<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>资金管理</el-breadcrumb-item>
                    <el-breadcrumb-item>国家信息配置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="searchright">
                        <el-button size="small" type="primary" @click="openadd">+添加国家信息配置</el-button>
                    </div>
                    <div class="top"></div>
                    <el-table :data="list" :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="values" label="国家货币"></el-table-column>
                        <el-table-column prop="name" label="国家名称"></el-table-column>
                        <el-table-column prop="rate" label="汇率"></el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="120">
                            <template slot-scope="scope">
                                <el-button size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <pop-up :title="status == 1 ? '添加国家信息配置' : '编辑国家信息配置'" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box" style="width: 900px;">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="国家货币" prop="values">
                        <el-input v-model="form.values" :min="0" :max="1"/>
                    </el-form-item>
                    <el-form-item label="国家名称" prop="name">
                        <el-input v-model="form.name" :min="0" :max="1"/>
                    </el-form-item>
                    <el-form-item label="汇率" prop="rate">
                        <el-input-number v-model="form.rate" :min="0" />
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    id: null,
                    values: null,
                    name: null,
                    rate: null,
                },
                rules:{
                    values:[{ required: true, trigger: 'blur' },],
                    name:[{ required: true, trigger: 'blur' },],
                    rate:[{ required: true, trigger: 'blur' },],
                },
                defaultProps: {
                    label: "name",
                    isLeaf: 'isLeaf'
                },
                loading: false,
                showadd: false,
                list: [],
            }
        },
        created() {
            this.getList()
        },
        methods: {
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    values: null,
                    name: null,
                    rate: null,
                }
            },
            openedit(row) {
                this.status = 2
                this.form.id = row.id
                this.form.values = row.values
                this.form.name = row.name
                this.form.rate = row.rate
                this.showadd = true
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            getconfirm() {
                if(this.form.rate==null || this.form.rate==""){
                    this.form.rate = 1
                }
                if (this.status == 1) {
                    _api.post(apiurl.nationInfo, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            values: null,
                            name: null,
                            rate: null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.nationInfo, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            values: null,
                            name: null,
                            rate: null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
            },
            getList() {
                this.loading = true
                _api.get(apiurl.nationInfo, {
                }).then(res => {
                    this.loading = false
                    this.list = res.data
                })
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .top {
        margin-top: 15px;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>